export default [
  {
    title: 'Überblick',
    icon: { icon: 'tabler:chart-pie-filled' },
    to: 'dashboard',
  },
  {
    title: 'Promotoren',
    icon: { icon: 'material-symbols:person' },
    to: 'promotor',
    feature: 'promoters'
  },
  {
    title: 'Empfehlungen',
    icon: { icon: 'bxs:like' },
    to: 'recommendation',
    feature: 'recommendations'
  },
  {
    title: 'Empfehlungsseite',
    icon: { icon: 'material-symbols:mobile-friendly-outline' },
    to: 'cms-recommendation-pages',
    feature: 'recommendation-pages'
  },
  {
    title: 'Bonusprogramm',
    icon: { icon: 'mdi-account-multiple' },
    to: 'customer-pages',
    feature: 'referral-program'
  },
  {
    title: 'Team',
    icon: { icon: 'material-symbols:groups' },
    to: 'teams',
    feature: 'teams'
  },
  {
    title: 'NPS Umfragen',
    icon: { icon: 'mdi-chart-line-stacked' },
    to: 'net-promoter-score',
    feature: 'net-promoter-score'
  },
]
