import {defineStore} from 'pinia'
import axios from "axios";
import {useAppStore} from "@/stores/app";
import {useToast} from "vue-toastification";
import {useUserStore} from "@/stores/user";
import {handleAuthError} from "@/stores/includes/interceptors";
import {
    loadTokenExpiryFromStorage,
    // loadTokenFromStorage,
    // updateToken,
    updateTokenExpiry
} from "@/stores/includes/oauth";
import {resetCookieValue} from "@/stores/includes/cookies";
import {
    getBoolFromStorage,
    getStringFromStorage,
    setBoolToStorage,
} from "@/stores/includes/storage";

const toast = useToast();

export const sessionsCookieName = 'ts_session'
export const localStorageRefreshingFlag = 'ts_refreshing'
export const localStorageAuthExpiresKey = 'ts_auth_expires_at'

// TODO: Change back to 5 minutes again
//export const refreshBeforeMilliseconds = 5 * 60 * 1000 // 5 minutes
export const refreshBeforeMilliseconds = 12 * 1000 // 12 seconds

const getExpireAt = (ttl) => new Date((new Date).getTime() + ttl * 1000).getTime()

export const useAuthStore = defineStore('auth', {
    state: () => ({
        expiresAt : null,
        refreshFunctionId: null,
    }),
    getters: {
        // We need to set that in local storage, so we can redirect the user, event when the store is not booted yet
        authenticated: (state) => (state.expiresAt ?? getBoolFromStorage(localStorageAuthExpiresKey)) > Date.now(),
    },
    actions: {
        /**
         * Initializes the authentication system
         *
         * @returns {Promise<void>}
         */
        async init() {

            // Add interceptor for auth token refreshing
            axios.interceptors.response.use(
                (response) => response,
                (error) => handleAuthError(error)
            )

            // Listen on local storage events
            window.addEventListener('storage', (event) => {
                // TODO: We can add here a synchronized login/logout feature on all tabs
                if (event.key === localStorageAuthExpiresKey) {
                    // We can savely do that here, since localStorage events fired from our own requests, are not
                    // firing local events, so we do not make a recursive function...
                    updateTokenExpiry(getStringFromStorage(localStorageAuthExpiresKey))
                }
            });

            // Initially set to false, to avoid lock up
            setBoolToStorage(localStorageRefreshingFlag, false)

            // loadTokenFromStorage()
            loadTokenExpiryFromStorage()
        },
        /**
         * Tries a login
         *
         * @param email
         * @param password
         * @returns {Promise<boolean>}
         */
        async login(email, password) {
            try {
                const { data : { expires_in } } = await axios.post('/api/auth/login', {
                    email: email,
                    password: password,
                });

                // Update state
                updateTokenExpiry(getExpireAt(expires_in))

                // Boot the application
                const app = useAppStore()

                return await app.boot()
            } catch (e) {
                console.error("Error during login")
                console.error(e)

                switch(e.response.data.error) {
                    case 'Unauthorized':
                        toast.error("Fehler beim Login. Prüfe Benutzername und Passwort!");
                        break;
                    case 'team_account_closed':
                        toast.error("Dieser Account wurde durch das Auflösen eines Teams geschlossen!");
                }

                return false;
            }
        },
        /**
         * Refreshes the token
         *
         * @returns {Promise<void>}
         */
        async refresh() {

            console.log("Refreshing the auth token")

            if (this.getRefreshStatus()) {
                console.log("Currently another tab is refreshing, skipping to avoid race conditions")
                return
            }

            // Flag to refresh
            setBoolToStorage(localStorageRefreshingFlag, true)

            try {
                const { data : { expires_in } } = await axios.post('/api/auth/refresh');

                // Update state
                updateTokenExpiry(getExpireAt(expires_in))

                // Update flag
                setBoolToStorage(localStorageRefreshingFlag, false)

            } catch (e) {
                console.error("Error during refresh")
                console.error(e)
                toast.error("Sitzung abgelaufen. Bitte melde dich erneut an!")

                // Log the user out
                await this.reset()

                // Update flag
                setBoolToStorage(localStorageRefreshingFlag, false)

                // Go back to login
                window.location.href = "/login"

            }
        },
        getRefreshStatus() {
            return getBoolFromStorage(localStorageRefreshingFlag)
        },
        /**
         * Logs out the current user
         *
         * @returns {Promise<boolean>}
         */
        async logout() {
            try {

                // Revoke bearer token
                // We do not call it via await to fasten the logout process -> Session invalidation on server side is not need to be waited for
                axios.post('/api/auth/logout').then() // The then is only there, so that my autism doesn't trigger when PhpStorm says I need to await for an async call

                // Reset state
                await this.reset()

                return true
            } catch (e) {
                console.error("Error during logout")
                console.error(e)
                toast.error("Fehler beim Logout")
                return false
            }
        },
        async updatePassword(password, passwordConfirmation) {
            try {
                await axios.post(`/api/auth/password`, {
                    'password' : password,
                    'password_confirmation' : passwordConfirmation
                })
                toast.success("Passwort aktualisiert")

                await this.reset()

                return true
            } catch (error) {
                toast.error("Leider ist das aktualisieren des Passworts fehlgeschlagen")
                console.error(error)
            }
            return false
        },
        /**
         * Resets the auth state
         *
         * @returns {Promise<void>}
         */
        async reset() {

            console.log("Resetting auth state")

            // Reset session cookie
            resetCookieValue(sessionsCookieName)

            // Update state
            updateTokenExpiry(null)

            // Reset refreshing flag (to be sure)
            setBoolToStorage(localStorageRefreshingFlag, false)

            // Also reset user store
            const user = useUserStore();

            user.reset();

            // Reboot the app
            const app = useAppStore()
            await app.boot()
        },
        async requestResetPassword(email){
            try {
                await axios.post('api/auth/password/email', {
                    'email' : email
                })
                return true
            } catch (error) {
                if (error.response.status !== 400) {
                    toast.error("Fehler beim anfragen des Reset Links")
                    return null
                }

                if (error.response.data.status === 'passwords.throttled') toast.error("Zu viele Anfragen")
                if (error.response.data.status === 'passwords.user') toast.error("Diese Mail existiert nicht")

                return error.response.data.status
            }
        },
        async resetPassword(email, password, passwordConfirmation, token){
            try {
                await axios.post('api/auth/password/reset', {
                    'email' : email,
                    'password' : password,
                    'password_confirmation' : passwordConfirmation,
                    'token' : token
                })
                return true
            } catch (error) {
                if (error.response.status !== 400) {
                    toast.error("Fehler beim setzen des Passwortes")
                    return null
                }

                if (error.response.data.status === 'passwords.token') toast.error("Ungültiger Reset-Link")
                if (error.response.data.status === 'passwords.user') toast.error("Diese Mail stimmt nicht überein")

                return error.response.data.status
            }
        }
    }
})